<template>
  <section>
    <h3 class="title-h2 text-center mb-5 pt-8">Frequently Asked Questions</h3>
    <p class="faq-subtitle text-center mb-15">
      Have questions? We’ve got answers.
      <br />
      You can find here what you are looking for.
      <br />
      Otherwise, you're welcome to
      <NuxtLink to="/contact">contact us</NuxtLink>
    </p>
    <AccordionList :items="props.items" class="mb-16" />
  </section>
</template>

<script setup>
import AccordionList from '../components/AccordionList.vue';

const props = defineProps({
  items: { type: Array, required: true },
});
</script>

<style scoped></style>
