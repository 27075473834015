<template>
  <v-expansion-panels variant="accordion">
    <v-expansion-panel v-for="item in items" :key="item.title">
      <v-expansion-panel-title>
        <h3 class="accordion-title">
          {{ item.title }}
        </h3>
        <template #actions="{ expanded }">
          <v-icon
            :color="expanded ? 'info' : ''"
            :icon="expansionPanelTitleIcon(expanded)"
          />
        </template>
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="accordion-text" v-html="item.text"></p>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import { mdiMinusBoxOutline, mdiPlusBoxOutline } from '@mdi/js';

defineOptions({
  name: `AccordionList`,
});

defineProps({
  items: { type: Array, default: () => [] },
});

const expansionPanelTitleIcon = (expanded) =>
  expanded ? mdiMinusBoxOutline : mdiPlusBoxOutline;
</script>

<style scoped>
.accordion-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* Text color 1 */
  color: #242527;
}

.accordion-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* Text color 2 */
  color: #8e8e8e;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding-top: 4px;
}
</style>
